.c-footer {
  padding: remify(48px) remify(16px);
  background-color: useRGB(--primary-100);
  color: useRGB(--white);
  background-image: url('../images/main-cover.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  margin-top: spacing(xlarge);

  &:before {
    content: '';
    background-color: rgba( var(--primary-100), .85 );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

}

.c-footer__inner {
  max-width: remify($setting-site-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );
  grid-column-gap: remify(16px);
  grid-row-gap: remify(24px);
}

.c-footer__pdf-link {
  margin-top: spacing(medium);
}

.c-footer__section-title {
  font-weight: fw(bold);
  text-transform: uppercase;
  margin-bottom: remify(16px);
  height: remify(60px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.c-footer__section {

  .media {
    margin-bottom: remify(12px);

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      vertical-align: middle;
    }

  }

  .media__txt {
    margin-left: remify(8px);
    @include font-scale(XS);

    a {
      @include font-scale(XS);
      color: currentColor;
      text-decoration: none;
    }

  }

}

.c-footer__nav {
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 1 1 50%;
    margin-bottom: remify(8px);
  }
  
  li a,
  li a:hover {
    color: useRGB(--white);
  }

}

.c-footer__socials {
  display: flex;
  align-items: center;

  a {
    margin-right:remify(16px);
  }

}

.c-footer__socials svg {
  fill: useRGB(--white);
}

/*------------------------------------*
  Notop modifier
*------------------------------------*/
.c-footer--notop {
  margin-top: 0;
}