.c-carousel {
  position: relative;
  max-width: remify($setting-site-width);
  margin: 0 auto;
}

.c-carousel__container {

  .c-paper__article & {
    display: grid;
  }

}

.c-carousel__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 spacing(medium);

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.disabled {
      display: none;
    }

  }

  .prev-btn {
    margin-right: auto;
  }

  .next-btn {
    margin-left: auto;
  }

}

.c-carousel__dots {
  text-align: center;
  position: relative;
  z-index: 1;
  top: remify(16px);
  margin-top: remify(24px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: remify(54px);
}

.c-carousel__dot {
  outline: none;

  span {
    width: remify(32px);
    height: remify(4px);
    margin: remify(5px) remify(7px);
    background-color: useRGB(--secondary-5);
    display: block;
    transition: opacity .3s ease;
  }

}

.c-carousel__dot.active {

  span {
    background-color: useRGB(--primary-60);
  }

}

/*------------------------------------*
  Testimonials modifier
*------------------------------------*/

.c-carousel--testimonials {
  max-width: remify(740px);
}