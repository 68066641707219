.c-partners__title {
  @include font-scale(XL);
  font-weight: fw(bold);
  margin-bottom: spacing(small);
  text-align: center;
  color: useRGB(--primary-100);
  letter-spacing: remify(1px);
}

.c-partners__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // max-width: remify($setting-site-width);
  max-width: remify(800px);
  margin: 0 auto;
}

.c-partners__item {
  padding: spacing(small);
  flex: 0 0 remify(100px);

  @include min-screen(bp(tablet)) {
    flex: 0 0 remify(135px);
  }

}

.c-partners__item img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: .3s all;

  &:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }

}
