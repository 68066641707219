.typo-xxlarge {
  @include font-scale(XXL);
}

.typo-xlarge {
  @include font-scale(XL);
}

.typo-large {
  @include font-scale(L);
}

.typo-medium {
  @include font-scale(M);
}

.typo-body,
p {
  @include font-scale(BODY);
}

.typo-small {
  @include font-scale(S);
}

.typo-xsmall {
  @include font-scale(XS);
}

.typo-xxsmall {
  @include font-scale(XXS);
}