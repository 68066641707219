@charset "UTF-8";
// SETTINGS
// Settings are for variables used site-wide.
// Typical settings are color variables, base font size and line height, breakpoints variables, etc.
@import "01-settings/settings.typo.scss";
@import "01-settings/settings.generic.scss";
@import "01-settings/settings.breakpoints.scss";
@import "01-settings/settings.colors.scss";
@import "01-settings/settings.zindex.scss";
@import "01-settings/settings.spacings.scss";
@import "01-settings/settings.shadows.scss";

// TOOLS
// Tools holds mixins and functions.
@import "02-tools/tools.map-deep-get.scss";
@import "02-tools/tools.breakpoints.scss";
@import "02-tools/tools.spacings.scss";
@import "02-tools/tools.colors.scss";
@import "02-tools/tools.backgrounds.scss";
@import "02-tools/tools.mixins.scss";
@import "02-tools/media-queries.scss";
@import "02-tools/tools.typo.scss";
@import "02-tools/tools.shadows.scss";

// GENERIC
// Generic is the first layer actually producing some CSS.
// This layer is seldom modified, and is usually the same across any projects you work on.
// It contains things like Normalize. css, global box-sizing rules, CSS resets and so on.
@import "03-generic/generic.box-sizing.scss";
@import "03-generic/generic.normalize.scss";
@import "03-generic/generic.reset.scss";

// ELEMENTS
// This contains bare, un-classed HTML elements.
// The Elements layer is typically the last one in which we’d find bare, element-based selectors,
// and is very rarely added to or changed after initial setup.
// Once we have defined element-level styles, all additions and deviations should be implemented using classes.
@import "04-elements/elements.form.scss";
@import "04-elements/elements.images.scss";
@import "04-elements/elements.page.scss";
@import "04-elements/elements.typo.scss";
@import "04-elements/elements.links.scss";
@import "04-elements/elements.lists.scss";
@import "04-elements/elements.buttons.scss";
@import "04-elements/elements.svgs.scss";

// OBJECTS
// This is the first layer using classes. Classes are used to style non cosmetic design patterns (like OOCSS objects).
// Wrappers, layout systems and media object are perfect candidates.
@import "05-objects/_object.grid.scss";
@import "05-objects/_object.media.scss";
@import "05-objects/_object.section.scss";
@import "05-objects/_object.table.scss";
@import "05-objects/_objects.antipodes.scss";
@import "05-objects/_objects.wrapper.scss";

// VENDOR
// Third party css files
@import "06-vendors/owl.carousel.scss";
@import "06-vendors/owl.theme.default.scss";
@import "06-vendors/micromodal.scss";

// COMPONENTS
// The Components layer is where we begin to style recognisable pieces of UI.
// We’re still binding onto classes here, so our specificity hasn’t yet increased.
// However, this layer is more explicit than the last one in that we are now styling explicit,
// designed pieces of the DOM. We shouldn’t find any selectors with a lower specificity than one class in this layer.
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/_icon/_icon.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/actions/_action.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/actions/atom-button/_button.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/actions/atom-link/_link.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/brackets/_brackets.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/card/_card.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/cards/_cards.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/carousel/_carousel.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/contact-box/_contact-box.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/contact-form/_contact-form.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/contacts/_contacts.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/decorated-list/_decorated-list.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/footer/_footer.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/header/_header.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/hero/_hero.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/job-position/_job-position.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/logo/_logo.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/modal/_modal.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/navigation/_navigation.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/paper/_paper.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/partners/_partners.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/progress/_progress.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/response-message/_response-message.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/strip/_strip.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/testimonial/_testimonial.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/token-colors/_token-colors.scss";
@import "/Users/antoniomatera/Sites/iamatek/src/views/01-components/topbar/_topbar.scss";

// TRUMPS
// It is inelegant and heavy- handed, and contains utility and helper classes, hacks and overrides.
// A lot of the declarations in this layer will carry !important
@import "07-trumps/_trumps.blame.scss";
@import "07-trumps/_trumps.utilities.scss";