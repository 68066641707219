.c-strip {
  position: relative;
}

.c-strip__inner {
  max-width: remify($setting-site-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;

  @include min-screen(bp(tablet)) {
    flex-direction: row;
    align-items: center;
    min-height: remify(450px);
  }

}

.c-strip__img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;

  @include min-screen(bp(tablet)) {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 50%;
  }

}

.c-strip__content {
  padding: remify(48px) remify(16px);

  @include min-screen(bp(tablet)) {
    width: 50%;
    margin-left: auto;
    padding: remify(48px);
  }

}

.c-strip__title,
.c-strip__title-sibling {
  display: inline;
}

.c-strip__title {
  @include font-scale(XL);
  font-weight: fw(bold);
  color: useRGB(--primary-100);
  letter-spacing: remify(1px);
}

.c-strip__title-sibling {
  @include font-scale(S);
  color: useRGB(--secondary-60);
}

.c-strip__subtitle {
  @include font-scale(M);
  font-weight: fw(bold);
  color: useRGB(--primary-60);
}

.c-strip__text {
  margin-top: remify(16px);
  color: useRGB(--secondary-80);
}

.c-strip__anchor {
  margin-top: remify(4px);
  color: RGB(var(--primary-60));
}

.c-strip__progress {
  margin-top: remify(24px);
}

/*------------------------------------*
  Contain modifier
*------------------------------------*/
.c-strip--contain {
 
  .c-strip__img {
    background-size: contain;
  }

}

/*------------------------------------*
  Edge modifier
*------------------------------------*/
.c-strip--edge {
 
  .c-strip__img {
    background-size: contain;
    background-position: center left;
  }

}

/*------------------------------------*
  Reverse modifier
*------------------------------------*/
.c-strip--reverse {

  .c-strip__img {
    @include min-screen(bp(tablet)) {
      left: initial;
      right: 0;
    }    
  }

  .c-strip__content {
  
    @include min-screen(bp(tablet)) {
      margin-right: auto;
      margin-left: initial;
    }
  
  }

}

/*------------------------------------*
  Image first modifier
*------------------------------------*/
.c-strip--imgfirst {

  .c-strip__img {
    @include max-screen(bp(tablet)) {
      height: remify(140px);
    }
  }

  .c-strip__inner {
    flex-direction: column;
  }

  .c-strip__title-container,
  .c-strip__subtitle {
    text-align: center;

    @include min-screen(bp(tablet)) {
      text-align: left;
    }

  }

  .c-strip__content {
    padding: remify(24px) remify(16px);
  }

}