.c-navigation {
  background-color: useRGB(--primary-80);
  padding: remify(16px);

  @include min-screen(bp(tablet)) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: useRGB(--white);
  }

}

.c-navigation__logo {
  margin: 0 remify(48px);
  display: none;

  @include min-screen(bp(tablet-p)) {
    display: block;
  }

  img {
    width: remify(230px);
  }

}

.c-navigation__list {
  
  @include min-screen(bp(tablet)) {
    display: flex;
  }

  a {
    display: inline-block;
    text-decoration: none;
    padding: remify(8px) 0;
    color: useRGB(--white);
    font-weight: fw(bold);

    &:hover {
      text-decoration: underline;
      
      @include max-screen(bp(tablet)) {
        color: useRGB(--white);
      }
    }

    @include min-screen(bp(tablet)) {
      padding: remify(24px);
      color: useRGB(--primary-100);
    }

  }

}