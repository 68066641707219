// non usare direttamente!
$base-font-sizing: 16; 

// used by createFont
$base-line-height: 1.5; // 24(px) / 16(px) = 1.5
$font-cap-height: 0.68;
// $font-rhythmUnit: round( $base-font-size * $base-line-height );
$font-rhythmUnit: 1rem * $base-line-height;

// @font-face {
//   font-family: 'MyWebFont';
//   src: url('../fonts/AlteHaasGroteskRegular.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/AlteHaasGroteskRegular.ttf');
// }


// $font-stack: 'MyWebFont', 'Lato', sans-serif;
$font-stack: 'Lato', sans-serif;;

$font-weights: (
  light: 300,
  regular : 400,
  bold    : bold,
  black: 900
);

$font-scale: (
  HUGE: (
    desktop: (
      fs: 60px,
      lh: 72px
    ),
    mobile: (
      fs: 33px,
      lh: 48px
    )
  ),
  XXL: (
    desktop: (
      fs: 33px,
      lh: 48px
    ),
    mobile: (
      fs: 28px,
      lh: 40px
    )
  ),
  XL: (
    desktop: (
      fs: 28px,
      lh: 48px
    ),
    mobile: (
      fs: 24px,
      lh: 32px
    )
  ),
  L: (
    desktop: (
      fs: 23px,
      lh: 32px
    ),
    mobile: (
      fs: 21px,
      lh: 32px
    )
  ),
  M: (
    desktop: (
      fs: 19px,
      lh: 32px
    ),
    mobile: (
      fs: 18px,
      lh: 30px
    )
  ),
  BODY: (
    desktop: (
      fs: 16px,
      lh: 24px
    ),
    mobile: (
      fs: 16px,
      lh: 24px
    )
  ),
  S: (
    desktop: (
      fs: 14px,
      lh: 24px
    ),
    mobile: (
      fs: 14px,
      lh: 24px
    )
  ),
  XS: (
    desktop: (
      fs: 12px,
      lh: 16px
    ),
    mobile: (
      fs: 12px,
      lh: 16px
    )
  ),
  XXS: (
    desktop: (
      fs: 10px,
      lh: 16px
    ),
    mobile: (
      fs: 10px,
      lh: 16px
    )
  )
);