.c-decorated-list__item {
  @include font-scale(BODY);

  a {
    display: block;
    padding: remify(8px) remify(16px);
    background-color: #f5f5f5;
    text-decoration: none;
    font-weight: fw(bold);
    color: useRGB(--secondary-100);
  }

  & + & {
    margin-top: spacing(xxsmall);
  }

}

.c-decorated-list__item--active {
  border-left: remify(5px) solid useRGB(--primary-40);
}