.c-form {

  fieldset {
    border: none;
  }

}

.c-form__field {

  & + & {
    margin-top: spacing(medium);
  }

}

.c-form__label {
  @include font-scale(BODY);
  font-weight: fw(bold);
  color: useRGB(--primary-100);
  display: block;

  sup {
    line-height: 0;
    font-size: remify(16px);
  }

}

.c-form__input {
  border: remify(1px) solid useRGB(--secondary-5);
  border-radius: remify(4px);
  padding: remify(12px);
  display: block;
  width: 100%;
  @include font-scale(BODY);
  outline: 0;
  transition: .3s ease;

  &:focus {
    border: remify(1px) solid useRGB(--primary-40);
  }

  @include placeholder {
    color: useRGB(--secondary-40);
  }

}

.c-form__actions {
  margin-top: spacing(xlarge);
  text-align: right;
}

.c-form__label-chkbox {
  @include font-scale(S);
  margin-left: spacing(xxxsmall);

  .label-link {
    @include font-scale(S);
  }

}