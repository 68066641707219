html {
  font-size: 100%;
  font-family: $font-stack;
  color: useRGB(--secondary-100);
  background-color: useRGB(--white);
}

main {
  padding-top: remify(64px);

  @include min-screen(bp(tablet)) {
    padding-top: 0;
  }

}