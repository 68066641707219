// Source: '_settings.typo.scss'
// Doc for postcss-responsive-type: https://www.npmjs.com/package/postcss-responsive-type

/*------------------------------------*\
  Responsive typo management
\*------------------------------------*/
@mixin font-scale($size: BODY, $min-range: bp(phone-p), $max-range: bp(tablet-p)) {
  font-size: responsive map-deep-get($font-scale, $size, "mobile", "fs") map-deep-get($font-scale, $size, "desktop", "fs");
  font-range: $min-range $max-range;
  line-height: responsive map-deep-get($font-scale, $size, "mobile", "lh") map-deep-get($font-scale, $size, "desktop", "lh");
  line-height-range: $min-range $max-range;
}

/*------------------------------------*\
  Font weight function
\*------------------------------------*/
@function fw($name) {
  @if(map-has-key($font-weights, $name)){
    @return map-get($font-weights, $name);
  } @else {
    @warn "Weight: Non esiste il weight " + $name;
  }
  @return regular;
}

/*------------------------------------*\
  Reset font properties mixin
\*------------------------------------*/
@mixin resetFont() {
  font-size: inherit;
  line-height: initial;
  padding-top: 0;
  margin-bottom: 0;
}