/*------------------------------------*\
  Important! 
  Everytime you need to add a color in this file, remember to 
  add it also inside its config.json file in:
  src/views/01-atoms/atom-colors/atom-colors.config.json
\*------------------------------------*/

@import "02-tools/tools.colors.scss";

:root {
  
  --white               : #{hex-to-rgb(#FFFFFF)};

  --primary-20          : #{hex-to-rgb(#1BBBE5)};
  --primary-40          : #{hex-to-rgb(#0D89BA)};
  --primary-60          : #{hex-to-rgb(#087FB3)};
  --primary-80          : #{hex-to-rgb(#0376A6)};
  --primary-100         : #{hex-to-rgb(#045E87)};
  --primary-120         : #{hex-to-rgb(#094561)};

  --secondary-5         : #{hex-to-rgb(#cfd3d6)};
  --secondary-10        : #{hex-to-rgb(#bec4c8)};
  --secondary-20        : #{hex-to-rgb(#acb4b9)};
  --secondary-40        : #{hex-to-rgb(#97a1a7)};
  --secondary-60        : #{hex-to-rgb(#7f8a93)};
  --secondary-80        : #{hex-to-rgb(#5f6e78)};
  --secondary-100       : #{hex-to-rgb(#191e22)};

  --feedback-success    : #{hex-to-rgb(#4FCF79)};
  --feedback-success-120: #{hex-to-rgb(#32B85F)};
  --feedback-success-130: #{hex-to-rgb(#2DA455)};

  --feedback-error      : #{hex-to-rgb(#E52138)};
  --feedback-error-120  : #{hex-to-rgb(#BB1629)};
  --feedback-error-130  : #{hex-to-rgb(#A41324)};

}