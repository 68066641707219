/*------------------------------------*\
    Keyframes
\*------------------------------------*/
// Usage: @include keyframes(slide-down)
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

/*------------------------------------*\
    Clearfix
\*------------------------------------*/
@mixin gnv-clearfix{
  @supports (display: flow-root) {
    display: flow-root;
  }

  @supports not (display: flow-root) {
    &:before,
    &:after {
      content: ' ';
      display: table;
    }

    &:after {
      clear: both;
    }
  }
}

/*------------------------------------*\
    Center with css translate
\*------------------------------------*/
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin centerer-horizontal {
  left: 50%;
  transform: translateX(-50%);
}

/*------------------------------------*\
    CSS Triangle
\*------------------------------------*/
/* usage:
&:before{
  content: "";
  position: absolute;
  @include css-triangle("up", 10px, red);
}
*/
@mixin css-triangle ($direction: "down", $size: 20px, $color: #000) {
  width: 0;
  height: 0;
  border-left: $size solid #{setTriangleColor($direction, "left", $color)};
  border-right: $size solid #{setTriangleColor($direction, "right", $color)};
  border-bottom: $size solid #{setTriangleColor($direction, "bottom", $color)};
  border-top: $size solid #{setTriangleColor($direction, "top", $color)};
}

//Utility function to return the relevant colour depending on what type of arrow it is
@function setTriangleColor($direction, $side, $color) {

  @if $direction == "left" and $side == "right"
  or  $direction == "right" and $side == "left"
  or $direction == "down" and $side == "top"
  or $direction == "up" and $side == "bottom" {
    @return $color
  } @else {
    @return "transparent";
  }
}

/*------------------------------------*\
    Px to Em
\*------------------------------------*/
@function em($pixels, $context: $base-font-sizing) {
  @return #{$pixels/$context}em;
}

/*------------------------------------*\
    Px to Rem
\*------------------------------------*/
@function remify($pixels) {
  @return $pixels / 16px * 1rem;
}

/*------------------------------------*\
	#SVG Background fallback
	using "The Invisible Gradient Technique"
\*------------------------------------*/

@mixin svgfallback($svg, $png) {
	background-image: url(../images/#{$png});
	background-image: linear-gradient(transparent, transparent), url(../images/#{$svg});
}

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}
/*------------------------------------*\
  Converts pixels in vw unit
\*------------------------------------*/
@function vw($size, $viewport: mobile) {
  @if($viewport==mobile) {
    @return strip-unit($size) * $px2vwRatio-mobile;
  } @else {
    @return strip-unit($size) * $px2vwRatio-desktop;
  }
}

/*------------------------------------*\
    Placeholders
\*------------------------------------*/
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}