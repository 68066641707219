.c-action {
  font-family: $font-stack;
  border-radius: remify($setting-buttons-border-radius);
  border: none;
  background-color: #ccc;
  transition: .3s;
  font-weight: fw(bold);
  display: inline-block;
  cursor: pointer;

  &:active {
    position: relative;
    top: remify(1px);
  }

  &[disabled], &:disabled {
    cursor: not-allowed;
  }

}

  .c-action__icon {
    vertical-align: middle;
    margin-right: remify(8px);

    .c-action--primary-loading &,
    .c-action--confirm-loading & {
      fill: useRGB(--white);
    }

    .c-action--secondary-loading & {
      fill: useRGB(--primary-80);
    }

  }

  .c-action__icon--rotate {
    animation: spinner-rotation 1s infinite linear;
  }

/*------------------------------------*\
  Primary button
\*------------------------------------*/

.c-action--primary {
  background-color: useRGB(--primary-40);
  color: useRGB(--white);

  &:hover {
    background-color: useRGB(--primary-60);
  }

  &:active {
    background-color: useRGB(--primary-80);
  }

}


/*------------------------------------*\
  Secondary button
\*------------------------------------*/
.c-action--secondary,
.c-action--text {
  box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--primary-40);
  background-color: useRGB(--white);
  color: useRGB(--primary-40);

  &:hover {
    box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--primary-60);
    color: useRGB(--primary-60);
  }

  &:active {
    box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--primary-80);
    color: useRGB(--primary-80);
  }

  .c-contact-box & {
    box-shadow: none;
  }

}

/*------------------------------------*\
  Confirm button
\*------------------------------------*/
.c-action--confirm {
  background-color: useRGB(--feedback-success);
  color: useRGB(--white);

  &:hover {
    background-color: useRGB(--feedback-success-120);
  }

  &:active {
    background-color: useRGB(--feedback-success-130);
  }

  &[disabled], &:disabled {
    background-color: useRGB(--feedback-success-20);
    color: rgba( var(--feedback-success), .6 );
  }

}

/*------------------------------------*\
  Delete button
\*------------------------------------*/
.c-action--delete {
  background-color: useRGB(--feedback-error);
  color: useRGB(--white);

  &:hover {
    background-color: useRGB(--feedback-error-120);
  }

  &:active {
    background-color: useRGB(--feedback-error-130);
  }

}

/*------------------------------------*\
  Neutral button
\*------------------------------------*/
.c-action--neutral {
  box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--secondary-20);
  background-color: useRGB(--white);
  color: useRGB(--secondary-40);

  &:hover {
    box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--secondary-40);
  }

}

/*------------------------------------*\
  White transparent
\*------------------------------------*/
.c-action--white-transp {
  box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--white);
  background-color: transparent;
  color: useRGB(--white);

  &:hover {
    box-shadow: inset 0px 0 0px remify(1.5px) useRGB(--white);
  }

}

/*------------------------------------*\
  Confirm loading button
\*------------------------------------*/
.c-action--confirm-loading {
  background-color: useRGB(--feedback-success-80);
  color: useRGB(var(--white));
}

/*------------------------------------*\
  Delete loading button
\*------------------------------------*/
.c-action--delete-loading {
  background-color: useRGB(--feedback-error-80);
  color: useRGB(var(--white));;
}

/*------------------------------------*\
  Big button
\*------------------------------------*/
.c-action--big {
  padding: remify(16px);
  @include font-scale(BODY);
}

/*------------------------------------*\
  Small button
\*------------------------------------*/
.c-action--small {
  padding: remify(8px) remify(16px);
  @include font-scale(S);
}

@include keyframes(spinner-rotation) {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}

/*------------------------------------*\
  Text button
\*------------------------------------*/
.c-action--text {
  box-shadow: none;
  padding: 0;
  background-color: transparent;

  &:hover,
  &:active {
    box-shadow: inherit;
  }

  // .c-abstract &,
  // .c-identity & {
  //   padding: 0;
  // }

  .c-identity & {
    margin: spacing(xlarge) 0;
  }

}
/*------------------------------------*
  Zero space button
*------------------------------------*/
.c-action--zero-space {
  padding: 0;
}

/*------------------------------------*
  Negative button
*------------------------------------*/
.c-action--negative,
.c-action--negative:hover {
  color: useRGB(--feedback-error);
}

/*------------------------------------*
  Icon button modifier
*------------------------------------*/
.c-action--icon {
  background: transparent;
}