.c-job-position {
  margin-top: spacing(large);
  padding-top: spacing(large);
  border-top: remify(1px) solid useRGB(--secondary-5);
}

.c-job-position__title {
  @include font-scale(L);
  color: useRGB(--primary-100);
  font-weight: fw(bold);
}

.c-job-position__location {
  @include font-scale(M);
  color: useRGB(--primary-80);
  font-weight: fw(bold);
  margin-bottom: spacing(small);
}

.c-job-position__description {
  @include font-scale(BODY);
  margin-bottom: spacing(small);
}

.c-job-position__details-title {
  font-weight: fw(bold);
}

.c-job-position__details-list {
  list-style-type: initial;
  list-style-position: inside;
  @include font-scale(BODY);
  margin-top: spacing(xxsmall);
}

.c-job-position__details + .c-job-position__details {
  margin-top: spacing(small);
}