.c-progress {

  & + & {
    margin-top: remify(16px);
  }

}

.c-progress__label {
  font-weight: fw(bold);
  margin-bottom: remify(8px);
}

.c-progress__bar {
  width: 100%;
  height: remify(8px);
  border-radius: remify(4px);
  background-color: useRGB(--secondary-5);
}

.c-progress__fill {
  width: 0%;
  height: 100%;
  border-radius: inherit;
  background-color: useRGB(--feedback-success-120);
  position: relative;
}

@for $i from 0 through 100 {
  .c-progress__fill--#{$i} {
    $value: ($i * 1%);
    width: $value;
  }
}

.c-progress__value {
  position: absolute;
  top: remify(-24px);
  right: remify(-13px);
  @include font-scale(XS);
  border: remify(1px) solid useRGB(--feedback-success-120);;
  border-radius: remify(20px);
  padding: 0 remify(6px);
  color: useRGB(--feedback-success-120);
}