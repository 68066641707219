.c-topbar {
  background-color: useRGB(--primary-120);
  padding: remify(8px) remify(16px);
  color: useRGB(--secondary-5);

  @include min-screen(bp(tablet)) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.c-topbar__company-info {

  @include min-screen(bp(tablet)) {
    display: flex;
    align-items: center;
  }

  .media {
    display: flex;
    align-items: center;
    margin-bottom: remify(8px);

    @include min-screen(bp(tablet)) {
      margin-bottom: 0;
      margin-right: remify(48px);
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    svg {
      vertical-align: baseline;
    }

  }

  .media__txt {
    margin-left: remify(8px);
    @include font-scale(XS);

    a {
      @include font-scale(XS);
      color: currentColor;
      text-decoration: none;
    }

  }

}

.c-topbar__socials {
  margin-top: remify(8px);
  @include min-screen(bp(tablet)) {
    margin-top: 0;
  }
}

.c-topbar__socials ul {
  display: flex;
  align-items: center;

  a {
    margin-right:remify(16px);
  }

}

.c-topbar__socials svg {
  fill: useRGB(--secondary-5);
}