.c-response-message {
  max-width: remify($setting-site-width);
  margin: 0 auto;
  padding: 0 spacing(small);
}

.c-response-message__image {
  text-align: center;
  margin-bottom: spacing(medium);
}

.c-response-message__title {
  @include font-scale(XL);
  font-weight: fw(bold);
  color: useRGB(--primary-100);
  text-align: center;
}

.c-response-message__subtitle {
  @include font-scale(M);
  color: useRGB(--secondary-100);
  text-align: center;
}

/*------------------------------------*
  Positive modifier
*------------------------------------*/

.c-response-message--positive {

  .c-response-message__title,
  .c-response-message__subtitle {
    color: useRGB(--feedback-success-120);
  }

}

/*------------------------------------*
  Negative modifier
*------------------------------------*/

.c-response-message--negative {

  .c-response-message__title,
  .c-response-message__subtitle {
    color: useRGB(--feedback-error);
  }

}