.o-grid {
  max-width: remify($setting-site-width);
  margin: 0 auto;
  padding: 0 spacing(small);
}

.o-grid__item {

  & + & {
    margin-top: spacing(large);

    @include min-screen(bp(tablet)) {
      margin-top: 0;
    }

  }

}

/*------------------------------------*
  two-col modifier
*------------------------------------*/
.o-grid--two-col {

  @include min-screen(bp(tablet)) {
    display: grid;
    grid-gap: spacing(large);
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }

}