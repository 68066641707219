.c-cards {
  max-width: remify($setting-site-width);
  margin: 0 auto;

  .c-paper & {
    margin-bottom: spacing(mid-large);
  }

}

.c-cards__title {
  @include font-scale(XL);
  font-weight: fw(bold);
  text-align: center;
  color: useRGB(--primary-100);
  letter-spacing: remify(1px);
}

.c-cards__subtitle {
  @include font-scale(XL);
  text-align: center;
  color: useRGB(--secondary-100);
  letter-spacing: remify(1px);
}

.c-cards__items {
  display: grid;
  grid-gap: remify(24px);
  grid-template-columns: repeat(3, minmax(remify(320px), 1fr));
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: spacing(mid-large);
}

.c-cards__item:first-child {

  @include max-screen(bp(tablet)) {
    padding-left: spacing(small);
  }
}

.c-cards__item:last-child {
  @include max-screen(bp(tablet)) {
    padding-right: spacing(small);
  }
}

/*------------------------------------*
  Blocks modifier
*------------------------------------*/
.c-cards--blocks {
  padding: 0 spacing(small);

  .c-cards__items {
    overflow-x: hidden;
    grid-template-columns: 1fr;
    grid-row-gap: spacing(mid-large);

    @include min-screen(bp(tablet)) {
      grid-template-columns: 1fr 1fr;
    }

    @include min-screen(bp(tablet-p)) {
      grid-template-columns: 1fr 1fr 1fr;
    }

  }

  .c-cards__item:first-child {

    @include max-screen(bp(tablet)) {
      padding-left: 0;
    }
  }

  .c-cards__item:last-child {
    @include max-screen(bp(tablet)) {
      padding-right: 0;
    }
  }

}

/*------------------------------------*
  two-cols modifier
*------------------------------------*/

.c-cards--two-cols {
  padding: 0 spacing(small);

  .c-cards__items {
    grid-template-columns: 1fr;

    @include min-screen(bp(tablet)) {
      grid-template-columns: 1fr 1fr;
    }
  }

}

/*------------------------------------*
  Bordered modifier
*------------------------------------*/
.c-cards--bordered {

  .c-cards__item {
    border-bottom: remify(1px) solid useRGB(--secondary-10);
    padding-bottom: spacing(medium);
    margin-bottom: spacing(medium);
  }

}
