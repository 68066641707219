.o-wrapper {
  margin: 0 auto;
  padding: 0 spacing(small);
}

/*------------------------------------*
  Widths modifiers
*------------------------------------*/
.o-wrapper--base {
  max-width: remify($setting-site-width);
}

.o-wrapper--small {
  max-width: remify(600px);
}

.o-wrapper--medium {
  max-width: remify(800px);
}