.c-card__img {
  height: remify(200px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: spacing(small);
}

  .c-card__img--contain {
    background-size: contain;
  }

.c-card__title {
  @include font-scale(M);
  font-weight: fw(bold);
}

.c-card__subtitle {
  @include font-scale(S);
  font-weight: fw(bold);
  color: useRGB(--secondary-80);
}

.c-card__datetime {
  @include font-scale(XS);
  color: useRGB(--secondary-80);
}

.c-card__text {
  margin-top: spacing(small);
  color: useRGB(--secondary-80);
}

.c-card__list {
  margin-top: spacing(small);
  @include font-scale(BODY);
  color: useRGB(--secondary-80);
  text-align: left;

  li {
    padding-left: spacing(medium);
    position: relative;
  }

  li+li {
    margin-top: spacing(xxsmall);
  }

  li::before {
    content: ' \25CF';
    position: absolute;
    left: 0;
    font-size: remify(12px);
  }

}

.c-card__url {
  color: useRGB(--primary-100);
  font-weight: fw(bold);
  margin-top: spacing(xsmall);
}

/*------------------------------------*
  Team modifier
*------------------------------------*/
.c-card--team {

  .c-card__img {
    background-size: contain;
  }

  .c-card__title,
  .c-card__subtitle {
    text-align: center;
  }

  .c-card__actions {
    text-align: center;
  }

}

/*------------------------------------*
  Deco modifier
*------------------------------------*/
.c-card--deco {
  text-align: center;

  .c-card__body {
    border: remify(1px) solid useRGB(--secondary-5);
    padding: spacing(xlarge) spacing(medium) spacing(medium);
    border-radius: remify(8px);
  }

  .c-card__img {
    background-size: initial;
    background-repeat: initial;
    background-position: initial;
    margin-bottom: 0;
    background-color: useRGB(--white);
    border-radius: 50%;
    height: remify(100px);
    width: remify(100px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: remify(50px);

    svg {
      fill: useRGB(--primary-100);
    }

  }

  .c-card__title {
    color: useRGB(--primary-80);
  }

  .c-card__actions {
    margin-top: spacing(medium);
  }

}
