$base-dimension: 16px;

.c-icon {
  fill: currentColor;

  .c-header__hamburger-label & {
    fill: useRGB(--primary-100);
  }

}

.c-icon--12 {
  width: remify(12px);
  height: remify(12px);
}

.c-icon--16 {
  width: remify($base-dimension);
  height: remify($base-dimension);
}

.c-icon--24 {
  width: remify(24px);
  height: remify(24px);
}

.c-icon--32 {
  width: remify(32px);
  height: remify(32px);
}

.c-icon--64 {
  width: remify(64px);
  height: remify(64px);
}

/*------------------------------------*
  Positive modifier
*------------------------------------*/
.c-icon--positive {
  fill: useRGB(--feedback-success);
}

/*------------------------------------*
  Negative modifier
*------------------------------------*/
.c-icon--negative {
  fill: useRGB(--feedback-error);
}