.c-paper {
  padding: 0 spacing(small);
  max-width: remify($setting-site-width);
  margin: remify(48px) auto;

  @include min-screen(bp(tablet)) {
    display: grid;
    grid-gap: remify(48px);
    grid-template-columns: 1fr remify(270px);
  }

}

.c-paper__title {
  @include font-scale(XL);
  font-weight: fw(bold);
  margin-bottom: spacing(small);
  color: useRGB(--primary-100);
}

.c-paper__article {
  margin-bottom: spacing(xlarge);

  @include min-screen(bp(tablet)) {
    margin-bottom: 0;
  }

  p,
  a {
    @include font-scale(M);
  }

  p + p {
    margin-top: spacing(small);
  }

  ul {
    margin: spacing(small) 0;
    list-style: initial;
    list-style-position: inside;
    @include font-scale(M);
  }

  li + li {
    margin-top: spacing(small);
  }

}

.c-paper__article--centered {
  text-align: center;
}

.c-paper__img {
  margin: spacing(medium) 0;
}

.c-paper__aside {

  &>* {
    margin-bottom: spacing(large);

    &:last-child {
      margin-bottom: 0;
    }

  }

}

/*------------------------------------*
  Fullbleed modifier
*------------------------------------*/
.c-paper--fullbleed {
  padding: 0 spacing(small);
  max-width: remify($setting-site-width);
  margin: remify(48px) auto;

  @include min-screen(bp(tablet)) {
    grid-template-columns: 1fr;
  }

}
