.c-hero {
  padding: remify(145px) remify(16px) remify(80px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  // margin-top: remify(60px);
  height: 300px;

  @include min-screen(bp(tablet)) {
    padding: remify(160px) remify(16px);
    // margin-top: 0;
    height: 500px;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

}

.c-hero__wrapper {
  width: 100%;
  max-width: remify(900px);
  margin: 0 auto;
}

.c-hero__title,
.c-hero__subtitle {
  color: useRGB(--white);
  text-align: center;
}

.c-hero__title {
  @include font-scale(XXL);
  font-weight: fw(bold);
}

.c-hero__subtitle {
  font-weight: fw(regular);
  @include font-scale(XL);
  // margin-bottom: remify(24px);
}

.c-hero__actions {
  text-align: center;

  .c-link + .c-link {
    margin-left: remify(16px);
  }

}

.c-hero__slideshow,
.c-hero__slideshow:after { 
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0; 
}
.c-hero__slideshow:after { 
  content: '';
  background-color: rgba( var(--primary-120), .8 ); 
}

.c-hero__slideshow li .slideshow__image { 
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 18s linear infinite 0s; 
}


.c-hero__slideshow .slideshow__txt-container { 
  z-index: 1000;
  position: absolute;
  width: 100%;
  // text-align: center;
  // opacity: 0;
  color: #fff;
  // animation: titleAnimation 12s linear infinite 0s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slideshow__txt-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 remify(16px);
}

.c-hero__slideshow li:nth-child(2) .slideshow__image { 
  animation-delay: 6s;
}

.c-hero__slideshow li:nth-child(3) .slideshow__image { 
  animation-delay: 12s;
}

// .c-hero__slideshow li:nth-child(2) .slideshow__txt-container { 
//   animation-delay: 6s;
// }

@keyframes imageAnimation { 
  0% { opacity: 0; animation-timing-function: ease-in; }
  8% { opacity: 1; animation-timing-function: ease-out; }
  34% { opacity: 1}
  44% { opacity: 0}
  100% { opacity: 0 }
}

// @keyframes titleAnimation { 
//   0% { opacity: 0 }
//   8% { opacity: 1 }
//   50% { opacity: 1 }
//   60% { opacity: 0 }
//   100% { opacity: 0 }
// }

/*------------------------------------*
  Static modifier
*------------------------------------*/
.c-hero--static {
  height: initial;

  &:before {
    content: '';
    background-color: rgba( var(--primary-100), .8 );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

/*------------------------------------*
  Huge modifier
*------------------------------------*/
.c-hero--impact {

  .c-hero__title {
    @include font-scale(HUGE);
    text-transform: uppercase;
    letter-spacing: remify(6px);
  }

  .c-hero__subtitle {
    font-weight: fw(light);
    @include font-scale(L);
  }

}

/*------------------------------------*
  Overlayless modifier
*------------------------------------*/
.c-hero--overlayless {

  &:before {
    display: none;
  }

}