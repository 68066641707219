.o-antipodes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.o-antipodes__item {
  margin: spacing(xxsmall) 0;
}

/*------------------------------------*
  More spaced modifier
*------------------------------------*/
.o-antipodes--more-spaced {

  .o-antipodes__item {
    margin: spacing(large) 0;
  }

}