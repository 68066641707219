.c-contact-box {
  padding: spacing(medium);
  background-color: useRGB(--primary-40);
  color: useRGB(--white);
}

.c-contact-box__title {
  font-weight: fw(bold);
  @include font-scale(BODY);
  margin-bottom: spacing(xxsmall);
}

.c-contact-box__body {
  @include font-scale(S);
  margin-bottom: spacing(small);
}