#g-recaptcha-response {
  display: block !important;
  position: absolute;
  margin: -78px 0 0 0 !important;
  width: 302px !important;
  height: 76px !important;
  z-index: -999999;
  opacity: 0;
}

iframe.iubenda-ibadge {
  vertical-align: middle;
  margin-left: spacing(xxxsmall);
}