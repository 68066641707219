a:not(.c-link),
a:not(.c-action) {
  @include font-scale(BODY);
  display: inline-block;
  color: useRGB(--primary-40);

  &:hover {
    color: useRGB(--primary-60);
  }

}