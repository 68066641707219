.o-section {
  padding: spacing(large) 0;

  @include min-screen(bp(tablet)) {
    padding: spacing(xlarge) spacing(small);
  }

}

.o-section--fullbleed {
  padding-left: 0;
  padding-right: 0;
}