.c-contacts {
  max-width: remify($setting-site-width);
  margin: 0 auto;
  padding: 0 spacing(small);

  @include min-screen(bp(tablet)) {
    display: grid;
    grid-gap: spacing(large);
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }

}

.c-contacts__box {

  & + & {
    margin-top: spacing(large);

    @include min-screen(bp(tablet)) {
      margin-top: 0;
    }

  }

}

.c-contacts__boxtitle {
  @include font-scale(XL);
  font-weight: fw(bold);
  color: useRGB(--primary-100);
  margin-bottom: spacing(medium);
}

.c-contacts__info {

  @include min-screen(bp(tablet)) {
    padding-top: spacing(small);
  }

  span, a {
    color: useRGB(--secondary-80);
    @include font-scale(BODY);
  }

  .media {
    margin-bottom: remify(12px);

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      vertical-align: middle;
    }

  }

}