.o-media {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.o-media__figure {
  padding-right: spacing(small);
}

.o-media__body {
  flex: 1 1 remify(300px);
}

/*------------------------------------*
  Vertical media
*------------------------------------*/
.o-media--vertical {
  display: block;
  text-align: center;

  .o-media__figure {
    padding: 0;
    margin-bottom: spacing(small);
  }

}