.c-logo {

  a & {
    vertical-align: middle;
  }

  .c-footer & {
    max-width: remify(200px);
  }

}