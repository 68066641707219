// Z-Index Scale (private vars)
// --------------------------------------------------
$zIndex-1:   100; // body
$zIndex-2:   200;
$zIndex-3:   300; //header
$zIndex-4:   400; 
$zIndex-5:   500; 
$zIndex-6:   600; 
$zIndex-7:   700; 
$zIndex-8:   800;
$zIndex-9:   900;
$zIndex-10:  1000;


// Z-Index Applications
// --------------------------------------------------

$zIndex-body             : $zIndex-1;
$zIndex-hamburger-content: $zIndex-2;
$zIndex-header             : $zIndex-3;