// // Source: '_settings.colors.scss'
// // URL for doc: https://codepen.io/jakealbaugh/post/using-sass-functions-to-access-complex-variable-maps

@function hex-to-rgb($hexcolor) {
  $red:red($hexcolor);
  $green:green($hexcolor);
  $blue:blue($hexcolor);
  $alpha:alpha($hexcolor);
  @return unquote("#{$red}, #{$green}, #{$blue}");
}

@function useRGB($var) {
  @return unquote("RGB(var(#{$var}))");
  // Cause Node SASS doesn't understand if you write 
  // color: rgb(var(--varname);
  // you have to use RGB(var(--varname);
}

// /*------------------------------------*\
//   Primaries colors function
//   Usage: 
//     background-color: var(--primary-80);
//     or
//     background-color: primary(error); if the color isn't in a nested map
// \*------------------------------------*/
// @function primary($color-name, $color-variant:null) {
//   @if ($color-variant != null) {
//     @return map-get(map-get($primary, $color-name), $color-variant);
//   } @else {
//     @return map-get($primary, $color-name);
//   }
// }

// /*------------------------------------*\
//   Secondaries colors function
//   Usage: 
//     background-color: var(--secondary-100);
// \*------------------------------------*/
// @function secondary($color-name, $color-variant:null) {
//   @if ($color-variant != null) {
//     @return map-get(map-get($secondary, $color-name), $color-variant);
//   } @else {
//     @return map-get($secondary, $color-name);
//   }
// }

// /*------------------------------------*\
//   Feedback colors function
//   Usage: 
//     background-color: var(--feedback-success);
// \*------------------------------------*/
// @function feedback($color-name, $color-variant:null) {
//   @if ($color-variant != null) {
//     @return map-get(map-get($feedback, $color-name), $color-variant);
//   } @else {
//     @return map-get($feedback, $color-name);
//   }
// }

// /*------------------------------------*\
//   Label colors function
//   Usage: 
//     background-color: var(--label-business);
// \*------------------------------------*/
// @function label($color-name, $color-variant:null) {
//   @if ($color-variant != null) {
//     @return map-get(map-get($label, $color-name), $color-variant);
//   } @else {
//     @return map-get($label, $color-name);
//   }
// }

// /*------------------------------------*\
//   Label colors function
//   Usage: 
//     background-color: var(--white);
// \*------------------------------------*/
// @function basic($color-name, $color-variant:null) {
//   @if ($color-variant != null) {
//     @return map-get(map-get($basic, $color-name), $color-variant);
//   } @else {
//     @return map-get($basic, $color-name);
//   }
// }