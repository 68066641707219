// @import "atom-colors.config.json";

.sg-colors {
  display: flex;
  flex-wrap: wrap;
}

.sg-color {
  flex: 0 1 150px;
  margin-right: remify(16px);
  margin-bottom: remify(32px);

  span {
    display: block;
    color: #666;
    text-transform: capitalize;
  }

  code {
    background-color: #eee;
    padding: remify(2px) remify(8px);
    font-size: remify(14px);
    color: #333;
    border-radius: remify(8px);
    display: inline-block;
    margin-top: remify(8px);
  }

}

  .sg-color__pattern {
    height: remify(80px);
    border-radius: remify(3px);
  }