.o-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

.o-table th,
.o-table td {
  text-align: left;
  padding: spacing(small);
  white-space: nowrap;
}

.o-table td {
  color: useRGB(--secondary-60);
}

.o-table tbody tr {
  border-bottom: remify(1px) solid useRGB(--secondary-10);
}