button {
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  font-family: $font-stack;
  padding: 0;
  
  & > * {
    pointer-events: none;
  }

}