.u-visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

*:focus {
  // @include outline;
  transition: none;
}

*:active {
  outline: initial;
}

/*------------------------------------*\
  Visibility classes
\*------------------------------------*/
.u-hidden {
  display: none;
}

/*------------------------------------*
  Text utility classes
*------------------------------------*/
.u-txt-center {
  text-align: center;
}

.u-barred-txt {
  text-decoration: line-through;
}

/*------------------------------------*\
  Object fit
\*------------------------------------*/

.u-OF-cover {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

.u-OF-contain {
  object-fit: contain;
  object-position: center;
  font-family: 'object-fit: contain; object-position: center;';
}

/*------------------------------------*\
  Stick
\*------------------------------------*/

.u-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.u-sticky--bottom {
  bottom: 0;
}

.u-sticky--top {
  top: 0;
}

.u-sticky:before,
.u-sticky:after {
  content: '';
  display: table;
}

/*------------------------------------*
  Radio/checkbox hack
*------------------------------------*/
.u-input-toggleReceiver {
  display: none !important;
}

.u-input-toggle:checked {
  &~.u-input-toggleReceiver {
    display: block !important;
  }
}

/*------------------------------------*
  This class is attached to <html>
*------------------------------------*/
.u-blackboard {
  background-color: useRGB(--secondary-100);
}

/*------------------------------------*
  Mobile init modifier
  This modifier makes Tabby working only on mobile
*------------------------------------*/
.u-tabs-mobile-init {
  margin-bottom: 0;

  [role="tablist"] {
    display: none;
  }

  [role="tabpanel"][hidden] {
    display: grid;
  }

  @include max-screen(bp(tablet)) {

    [role="tablist"] {
      display: flex;
    }

    [role="tabpanel"][hidden] {
      display: none;
    }

  }

}

/*------------------------------------*
  Spacing utility classes
*------------------------------------*/

// Bottoms
.u-mb-xxxs {
  margin-bottom: spacing(xxxsmall);
}

.u-mb-xxs {
  margin-bottom: spacing(xxsmall);
}

.u-mb-xs {
  margin-bottom: spacing(xsmall);
}

.u-mb-sm {
  margin-bottom: spacing(small);
}

.u-mb-mid {
  margin-bottom: spacing(medium);
}

.u-mb-lg {
  margin-bottom: spacing(large);
}

.u-mb-mid-lg {
  margin-bottom: spacing(mid-large);
}

.u-mb-xl {
  margin-bottom: spacing(xlarge);
}

// Tops
.u-mt-xxxs {
  margin-top: spacing(xxxsmall);
}

.u-mt-xxs {
  margin-top: spacing(xxsmall);
}

.u-mt-xs {
  margin-top: spacing(xsmall);
}

.u-mt-sm {
  margin-top: spacing(small);
}

.u-mt-mid {
  margin-top: spacing(medium);
}

.u-mt-lg {
  margin-top: spacing(large);
}

.u-mt-mid-lg {
  margin-top: spacing(mid-large);
}

.u-mt-xl {
  margin-top: spacing(xlarge);
}

/*------------------------------------*
  Credit card dots
*------------------------------------*/
.u-cc-dot {
  display: inline-block;
  vertical-align: text-bottom;

  &:nth-child(4n) {
    margin-right: spacing(medium);

    .c-abstract & {
      margin-right: spacing(xsmall);
    }

  }

  .c-card--credit &,
  .c-panel__header & {
    font-size: remify(36px);
  }

  .c-abstract & {
    font-size: remify(24px);
  }

}