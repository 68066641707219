.c-header {

  @include max-screen(bp(tablet)) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zIndex-header;
    box-shadow: 0 8px 16px 0 rgba(var(--secondary-100), 0.5);
  }
}

.c-header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  background-color: useRGB(--white);
  z-index: $zIndex-header;
}

.c-header__hamburger-content {
  display: none;
  flex: 1 0 100%;

  @include min-screen(bp(tablet)) {
    display: block;
  }

}

.c-header__logo,
.c-header__hamburger-label {
  padding: remify(16px);
}

.c-header__logo {
  width: remify(150px);
  display: inline-block;

  @include min-screen(bp(tablet)) {
    display: none;
  }
}

.c-header__hamburger-label {
  cursor: pointer;
  display: inline-block;

  @include min-screen(bp(tablet)) {
    display: none;
  }
}

.c-header__hamburger-chkbx {
  display: none;
}

.c-header__hamburger-chkbx:checked ~ .c-header__hamburger-content {
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  top: remify(64px);
  left: 0;
  width: 100%;
  z-index: $zIndex-hamburger-content;

  @include min-screen(bp(tablet)) {
    position: relative;
    top: 0;
    box-shadow: none;
  }

}

.c-header__hamburger-chkbx:checked ~ .c-header__top .c-header__hamburger-label--open {
  display: none;
}

.c-header__hamburger-label--close {
  display: none;
}

.c-header__hamburger-chkbx:checked ~ .c-header__top .c-header__hamburger-label--close {
  display: block;

  @include min-screen(bp(tablet)) {
    display: none;
  }

}

.c-header__overlay {
  display: none;
}

.c-header__hamburger-chkbx:checked ~ .c-header__overlay {
  content: '';
  display: block;
  position: fixed;
  background-color: rgba( var(--secondary-100), .9 );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;

  @include min-screen(bp(tablet)) {
    display: none;
  }

}