.c-testimonial {
  padding: 0 spacing(small);
}

.c-testimonial__person {
  text-align: center;
}

.c-testimonial__img {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: remify(130px);
  height: remify(130px);
  margin: 0 auto remify(16px);
}

.c-testimonial__name {
  font-weight: fw(bold);
}

.c-testimonial__role {
  color: useRGB(--secondary-80);
  @include font-scale(S);
}

.c-testimonial__person {
  margin-bottom: spacing(medium);
}

.c-testimonial__message {
  color: useRGB(--secondary-80);
  text-align: center;
}