.c-brackets {
  max-width: remify($setting-site-width);
  margin: 0 auto;
}

.c-brackets__img {
  // max-width: remify(60px);
  max-height: remify(100px);

  @include min-screen(bp(tablet-p)) {
    // max-width: remify(80px);
    max-height: remify(130px);
  }

}

.c-brackets__title {
  @include font-scale(XXL);
  font-weight: fw(bold);
  margin-bottom: remify(48px);
  text-align: center;
  color: useRGB(--primary-100);
  letter-spacing: remify(1px);
}

.c-brackets__items {
  display: grid;
  grid-template-columns: repeat(4, minmax(60%, 1fr));
  grid-gap: remify(16px);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include min-screen(bp(phablet)) {
    grid-template-columns: repeat(4, minmax(39%, 1fr));
  }

  @include min-screen(bp(tablet-p)) {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }

}

.c-brackets__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &>*+* {
    margin-top: spacing(xxsmall);
  }

}

.c-brackets__item-title {
  @include font-scale(M);
  font-weight: fw(bold);
  flex: 1;
  margin-bottom: spacing(xsmall);
}

.c-brackets__text {
  color: useRGB(--secondary-80);

  strong {
    color: useRGB(--secondary-100);
  }

}

/*------------------------------------*
  Modifier: no-overflow
*------------------------------------*/
.c-brackets--no-overflow {

  .c-brackets__items {
    overflow-x: initial;
    -webkit-overflow-scrolling: initial;
  }

}

/*------------------------------------*
  Modifier: three-cols-grid
*------------------------------------*/
.c-brackets--three-cols-grid {

  .c-brackets__items {
    grid-template-columns: repeat(2, 1fr);
  
    @include min-screen(bp(phablet)) {
      grid-template-columns: repeat(3, 1fr);
    }
  
  }

}